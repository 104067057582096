<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 qr-codes-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("utilities", "qr-codes", "qr-codes") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <!-- Advanced search start -->
      <section
        class="search-wrapper advanced-search level-search"
        v-if="search"
      >
        <div class="advanced-search-wrapper">
          <div class="search-field">
            <form @submit.prevent="submitSearch" novalidate>
              <input
                type="text"
                v-model="searchQuery"
                :placeholder="
                  displayLabelName('resources', 'resources', 'advanced-search')
                "
              />
              <div class="icon search">
                <icon icon="#cx-app1-search-16x16" width="16" height="16" />
              </div>
              <button
                class="clear-field"
                type="button"
                @click="removeAllFilters"
              >
                <icon icon="#cx-app1-cross-round" />
              </button>
            </form>
          </div>
          <button
            class="open-filters-button"
            @click="$router.push({ name: 'r_qr-code-filters' })"
          >
            <icon icon="#cx-app1-filter-16x16" />
            <span class="indicator" v-if="selectedFilters.length">{{
              selectedFilters.length
            }}</span>
          </button>
        </div>
        <ul
          class="search-filter-list"
          v-if="selectedFilters && selectedFilters.length"
        >
          <li
            class="search-filter-item"
            v-for="filter in selectedFilters"
            :key="filter.id"
          >
            <span class="name">{{ filter.name }}</span>
            <button
              class="search-filter-remove-btn"
              @click="removeSelectedFilter(filter)"
            >
              <icon icon="#cx-app1-cross-round" />
            </button>
          </li>
        </ul>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfCodes && listOfCodes.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !exportMode }"
        v-for="qrCode in listOfCodes"
        :key="qrCode.id"
      >
        <!-- start -->
        <router-link
          :to="{
            name: 'r_edit-qr-code',
            params: { qrCodeId: qrCode.id }
          }"
          class="clebex-item-content-wrapper"
          v-if="!exportMode"
        >
          <span class="label">
            <span class="inner-text favorite-right-info">
              <span class="highlight qr-code-title-box">{{
                qrCode.title
              }}</span>
              <span class="highlight">{{ qrCode.name }}</span>
              {{
                qrCode.level_name && qrCode.level_name.length
                  ? qrCode.level_name.join(", ")
                  : ""
              }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </router-link>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`qr-code${qrCode.id}`"
              name="id"
              :checked="selectedItems.includes(qrCode.id)"
              @change="handleSelection(qrCode)"
            />
            <label :for="`qr-code${qrCode.id}`">
              <div class="svg-icon">
                <svg-icon icon="checkmark-strait"></svg-icon>
              </div>
              <span class="inner-text favorite-right-info">
                <span class="highlight qr-code-title-box">{{
                  qrCode.title
                }}</span>
                <span class="highlight">{{ qrCode.name }}</span>
                {{
                  qrCode.level_name && qrCode.level_name.length
                    ? qrCode.level_name.join(", ")
                    : ""
                }}
              </span>
            </label>
          </div>
        </div>
        <!-- end -->
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="qr-codes-footer actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: exportMode }"
              @click="setExportMode"
            >
              {{ displayLabelName("utilities", "qr-codes", "select") }}
            </button>
          </li>
          <li v-if="exportMode" class="action" :style="exportButtonStyle">
            <button class="action-btn" @click="showDialog">
              <icon icon="#cx-men1-download-sample" />
            </button>
          </li>
          <li v-else class="action">
            <button @click="goToTemplates" class="action-btn">
              {{ displayLabelName("utilities.qr-codes.templates") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>

    <export-dialog
      v-if="showExportDialog"
      :qrCodes="selectedItems"
      @closeDialog="closeExportDialog"
    />
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "Receptions",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    ExportDialog: defineAsyncComponent(() =>
      import("@/components/qr-codes/ExportDialog")
    )
  },
  mixins: [paginationMixin],
  data() {
    return {
      search: false,
      searchQuery: "",
      showExportDialog: false,
      selectedItems: [],
      exportMode: false
    };
  },
  created() {
    this.getQrCodes();
  },
  computed: {
    ...mapState("qrCode", ["qrCodes", "selectedQrCode", "exportQrCodes"]),
    ...mapState("qrCodeFilters", [
      "selectedTypeId",
      "selectedLevelId",
      "searchActiveNum"
    ]),
    selectedFilters() {
      const filters = [];
      if (this.selectedTypeId !== null) {
        filters.push({
          name: this.displayLabelName("utilities", "qr-codes", "type"),
          type: "type"
        });
      }

      if (this.selectedLevelId !== null) {
        filters.push({
          name: this.displayLabelName("utilities", "qr-codes", "levels"),
          type: "level"
        });
      }

      return filters;
    },
    listOfCodes() {
      const query = this.searchQuery;
      if (this.qrCodes && this.qrCodes.length) {
        if (query && query.length > 1) {
          return this.qrCodes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.qrCodes;
        }
      }
      return null;
    },
    exportButtonStyle() {
      const visibility =
        this.exportMode && this.selectedItems.length ? "visible" : "hidden";

      return {
        visibility
      };
    }
  },
  watch: {
    searchActiveNum() {
      this.submitSearch();
    }
  },
  methods: {
    ...mapActions("qrCode", ["getQrCodes", "setQrCodes"]),
    ...mapActions("qrCodeFilters", ["setSelectedTypeId", "setSelectedLevelId"]),
    toggleSearch() {
      this.search = !this.search;
      if (!this.search && this.$route.name === "r_qr-code-filters") {
        this.$router.push({ name: "r_qr-codes" });
      }
    },
    removeSelectedFilter(filter) {
      this.setPaginationData(null);
      if (filter.type === "type") {
        this.setSelectedTypeId(null);
      }

      if (filter.type === "level") {
        this.setSelectedLevelId(null);
      }

      this.submitSearch();
    },
    removeAllFilters() {
      this.searchQuery = "";
      this.setSelectedTypeId(null);
      this.setSelectedLevelId(null);
      this.getQrCodes();
    },
    submitSearch() {
      this.getQrCodes();
    },
    handleSelection(code) {
      const index = this.selectedItems.findIndex(i => i.id === code.id);
      if (index < 0) {
        this.selectedItems.push({
          id: code.id,
          name: code.name,
          code: code.code
        });
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    setExportMode() {
      this.exportMode = !this.exportMode;
      this.$router.push({ name: "r_qr-codes" });
    },
    showDialog() {
      this.showExportDialog = true;
    },
    closeExportDialog() {
      this.showExportDialog = false;
    },
    goToTemplates() {
      this.$router.push({ name: "r_qr-code-templates" });
    }
  },
  beforeUnmount() {
    this.setSelectedTypeId(null);
    this.setSelectedLevelId(null);
    this.setQrCodes(null);
  }
};
</script>
